import { default as errorgo7w5Qk6bOMeta } from "C:/Install/Projects/lk_creative/pages/auth/error.vue?macro=true";
import { default as forgot_45passwordiCNtrRftryMeta } from "C:/Install/Projects/lk_creative/pages/auth/forgot-password.vue?macro=true";
import { default as loginInw2TgQVAWMeta } from "C:/Install/Projects/lk_creative/pages/auth/login.vue?macro=true";
import { default as registerWtE3T0pC8RMeta } from "C:/Install/Projects/lk_creative/pages/auth/register.vue?macro=true";
import { default as _91token_936mwlzjZGcjMeta } from "C:/Install/Projects/lk_creative/pages/auth/reset-password/[token].vue?macro=true";
import { default as _91id_93TTV1Yj0vswMeta } from "C:/Install/Projects/lk_creative/pages/auth/verify-email/[id].vue?macro=true";
import { default as indexI7oE4P3sSvMeta } from "C:/Install/Projects/lk_creative/pages/board/index.vue?macro=true";
import { default as index0AWAbWtZwmMeta } from "C:/Install/Projects/lk_creative/pages/board/invoices/index.vue?macro=true";
import { default as indexXvw0GTDti1Meta } from "C:/Install/Projects/lk_creative/pages/board/profitability/index.vue?macro=true";
import { default as indexBj4FinS6GWMeta } from "C:/Install/Projects/lk_creative/pages/board/shortage/index.vue?macro=true";
import { default as profile4OzTw9bKiWMeta } from "C:/Install/Projects/lk_creative/pages/board/user/profile.vue?macro=true";
import { default as indexPxWlme0VQVMeta } from "C:/Install/Projects/lk_creative/pages/index.vue?macro=true";
export default [
  {
    name: "auth-error",
    path: "/auth/error",
    component: () => import("C:/Install/Projects/lk_creative/pages/auth/error.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordiCNtrRftryMeta || {},
    component: () => import("C:/Install/Projects/lk_creative/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginInw2TgQVAWMeta || {},
    component: () => import("C:/Install/Projects/lk_creative/pages/auth/login.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerWtE3T0pC8RMeta || {},
    component: () => import("C:/Install/Projects/lk_creative/pages/auth/register.vue")
  },
  {
    name: "auth-reset-password-token",
    path: "/auth/reset-password/:token()",
    meta: _91token_936mwlzjZGcjMeta || {},
    component: () => import("C:/Install/Projects/lk_creative/pages/auth/reset-password/[token].vue")
  },
  {
    name: "auth-verify-email-id",
    path: "/auth/verify-email/:id()",
    meta: _91id_93TTV1Yj0vswMeta || {},
    component: () => import("C:/Install/Projects/lk_creative/pages/auth/verify-email/[id].vue")
  },
  {
    name: "board",
    path: "/board",
    component: () => import("C:/Install/Projects/lk_creative/pages/board/index.vue")
  },
  {
    name: "board-invoices",
    path: "/board/invoices",
    component: () => import("C:/Install/Projects/lk_creative/pages/board/invoices/index.vue")
  },
  {
    name: "board-profitability",
    path: "/board/profitability",
    component: () => import("C:/Install/Projects/lk_creative/pages/board/profitability/index.vue")
  },
  {
    name: "board-shortage",
    path: "/board/shortage",
    component: () => import("C:/Install/Projects/lk_creative/pages/board/shortage/index.vue")
  },
  {
    name: "board-user-profile",
    path: "/board/user/profile",
    component: () => import("C:/Install/Projects/lk_creative/pages/board/user/profile.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexPxWlme0VQVMeta || {},
    component: () => import("C:/Install/Projects/lk_creative/pages/index.vue")
  }
]