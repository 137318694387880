<template>
  <VitePwaManifest />
  <NuxtLoadingIndicator />
  <NuxtLayout :name="layout">
    <NuxtPage></NuxtPage>
  </NuxtLayout>
</template>
<script setup>
const { status, data } = useAuth();
let layout = 'guest';

if (status.value == 'authenticated') {
  layout = 'lk';
} 
</script>