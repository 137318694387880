import revive_payload_client_4sVQNw7RlN from "C:/Install/Projects/lk_creative/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/Install/Projects/lk_creative/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/Install/Projects/lk_creative/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/Install/Projects/lk_creative/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/Install/Projects/lk_creative/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/Install/Projects/lk_creative/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "C:/Install/Projects/lk_creative/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "C:/Install/Projects/lk_creative/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/Install/Projects/lk_creative/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "C:/Install/Projects/lk_creative/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "C:/Install/Projects/lk_creative/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_I4dbrL2rYz from "C:/Install/Projects/lk_creative/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import role_GSSSatFIfj from "C:/Install/Projects/lk_creative/plugins/role.js";
import toast_Zl2rZYKMaV from "C:/Install/Projects/lk_creative/plugins/toast.js";
import vue_pdf_embed_client_t0vihKYh7P from "C:/Install/Projects/lk_creative/plugins/vue-pdf-embed.client.js";
import vuetify_8NhHJigKc1 from "C:/Install/Projects/lk_creative/plugins/vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  plugin_I4dbrL2rYz,
  role_GSSSatFIfj,
  toast_Zl2rZYKMaV,
  vue_pdf_embed_client_t0vihKYh7P,
  vuetify_8NhHJigKc1
]