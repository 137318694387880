export default defineNuxtPlugin(async () => {
  const { data } = useAuth();
  const session = data.value;
  if (session && Object.keys(session).length && session?.role) {
    const userRoles = session.role;
    return {
      provide: {
        roleIs: (role) => userRoles.find((item) => item == role),
      },
    };
  } else {
    return false;
  }
});
